import React from "react";
import { Routes, Route } from "react-router-dom";
import Onboarding from "../pages/Onboarding/Onboarding";
import PaymentSuccess from "../pages/Onboarding/PaymentSuccess"
import ProgressSuccess from "../pages/Onboarding/ProgressSuccess"
import RootLayout from "../layout";
import Signup from "../pages/Auth/Signup";
import { loadStripe } from "@stripe/stripe-js";


const RouterConfig = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  return (
    <Routes>
      <Route path="/" element={<RootLayout />}>
        <Route path="/onboard" element={<Onboarding stripePromise={stripePromise} />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/payment-success" element={<PaymentSuccess stripePromise={stripePromise} />} />
        <Route path="/progress-success" element={<ProgressSuccess stripePromise={stripePromise} />} />
      </Route>
    </Routes>
  );
};

export default RouterConfig;
