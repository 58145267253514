import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Button,
    Radio,
} from "@mui/material";
import styles from "./styles/styles.module.css";
import { loadStripe } from "@stripe/stripe-js";
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import cogoToast from "cogo-toast";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentPlanCard = ({ title, price, billing, discount, offerTag, isSelected, onClick }) => {
    return (
        <Box
            onClick={onClick}
            className={`${styles.planCard} ${isSelected ? styles.activePlan : ""}`}
        >
            {offerTag && (
                <Typography variant="gothamSmall" className={styles.offerTag}>
                    {offerTag}
                </Typography>
            )}

            <Box className="flex justify-between">
                <Typography variant="gothamMedium" className={styles.planTitle}>
                    {title}
                </Typography>
                <Radio
                    checked={isSelected}
                    className={styles.radioButton}
                />
            </Box>
            <Box>
                <Box className="flex justify-between">
                    <Typography variant="gothamMediumLarge" className={styles.planPrice}>
                        {price}<span className={styles.perMonth}>/month</span>
                    </Typography>
                    {discount && (
                        <Typography variant="gothamSmall" className={styles.discountTag}>
                            {discount}
                        </Typography>
                    )}
                </Box>
                <Typography variant="gothamRegular" className={styles.planBilling}>
                    {billing}
                </Typography>
            </Box>
        </Box>
    );
};

const Payments = ({ setSelectedPlanValue, isClientSecret, selectedPlan, setSelectedPlan }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isRegistered, setIsRegistered] = useState(0);

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) return;
        try {
            const customerEmail = localStorage.getItem("LPCurrentUserEmail");
            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}/payment-success`,
                    payment_method_data: {
                        billing_details: {
                            email: customerEmail,
                        }
                    },
                },
            });
            localStorage.setItem("currentCompleted", 3);
        } catch (err) {
            console.error("Unexpected error during confirmPayment:", err);
        }
    };

    return (
        <form onSubmit={handleSubmit} className='px-4'>
            <Box className={styles.formPageContainer}>
                <Typography variant="gotham2XL" className={styles.title}>
                    Payment & Billing
                </Typography>
                <Stepper orientation="vertical">
                    <Step active>
                        <StepLabel
                            sx={{
                                "& .MuiStepIcon-root": {
                                    color: "white !important",
                                    border: "1px solid black",
                                    borderRadius: "50%",
                                },
                                "& .MuiStepIcon-text": { fill: "black" },
                            }}
                        >
                            <Typography variant="gothamLarge" className={`${styles.stepper} f-500`}>
                                Select Payment Plan
                            </Typography>
                        </StepLabel>
                        <StepContent>
                            <Box className={`${styles.stepper} ${styles.formFields} margin-top-35`}>
                                <PaymentPlanCard
                                    title="Annually"
                                    price="£14"
                                    billing="£168 billed annually"
                                    discount="-30% off"
                                    offerTag="LIMITED TIME OFFER"
                                    isSelected={selectedPlan === "annual"}
                                    onClick={() => { setSelectedPlan("annual"); setSelectedPlanValue(14) }}
                                />
                                <PaymentPlanCard
                                    title="Monthly"
                                    price="£20"
                                    billing="£20 billed monthly"
                                    isSelected={selectedPlan === "monthly"}
                                    onClick={() => { setSelectedPlan("monthly"); setSelectedPlanValue(20) }}
                                />
                            </Box>
                        </StepContent>
                    </Step>
                    <Step active>
                        <StepLabel
                            sx={{
                                "& .MuiStepIcon-root": {
                                    color: "white !important",
                                    border: "1px solid black",
                                    borderRadius: "50%",
                                },
                                "& .MuiStepIcon-text": { fill: "black" },
                            }}
                        >
                            <Typography variant="gothamLarge" className={`${styles.stepper} f-500`}>
                                Select Payment Method
                            </Typography>
                        </StepLabel>
                        <StepContent>
                            {!isClientSecret ? (
                                <Typography variant="body2" className={styles.loadingText}>
                                    Loading payment options...
                                </Typography>
                            ) : (
                                <PaymentElement />
                            )}
                            {errorMessage && (
                                <Typography color="error" variant="body2" mt={2}>
                                    {errorMessage}
                                </Typography>
                            )}
                        </StepContent>
                    </Step>
                </Stepper>
                <Box className={styles.submitButtonContainer}>
                    {/* <Button className={styles.secondaryButton} size="large" variant="contained">
                        Go Back
                    </Button> */}
                    <Button className={styles.primaryButton} size="large" variant="contained" type="submit" disabled={!stripe || !elements}>
                        Submit Payment
                    </Button>
                </Box>
            </Box>
        </form>
    );
};

const PaymentsWrapper = ({ clinicRegistrationData, stripePromise }) => {

    const [clientSecret, setClientSecret] = useState("");
    const [selectedPlanValue, setSelectedPlanValue] = useState(14);
    const [selectedPlan, setSelectedPlan] = useState("annual");
    const [isLoading, setIsLoading] = useState(true);

    const fetchClientSecret = async () => {
        setIsLoading(true);
        const userEmail = localStorage.getItem("LPCurrentUserEmail");
        const userName = localStorage.getItem("LPCurrentUserName");
        const storedProvideruid = localStorage.getItem("LPCurrentProvideruid");
        const storedClinicTitle = localStorage.getItem("LPCurrentClinicTitle");
        try {
            fetch(`${process.env.REACT_APP_STRIPE_INTENT_URL}`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    amount: selectedPlanValue * 100 * (selectedPlan === "annual" ? 12 : 1),
                    currency: "gbp",
                    provideruid: clinicRegistrationData.provideruid || storedProvideruid,
                    uid: clinicRegistrationData.provideruid || storedProvideruid,
                    title: clinicRegistrationData.title || storedClinicTitle,
                    email: userEmail,
                    name: userName,
                    plan: selectedPlan,
                }),
            }).then((res) => res.json())
                .then((data) => {
                    setIsLoading(false);
                    setClientSecret(data.clientSecret)
                });

        } catch (err) {
            console.error("Error fetching client secret:", err);
        }
    };

    useEffect(() => {
        fetchClientSecret()
    }, [selectedPlanValue])

    if (!isLoading) {
        const options = clientSecret
            ? {
                clientSecret,
                appearance: {
                    theme: "stripe",
                },
            }
            : null;
        const isClientSecretLoading = clientSecret ? true : false
        return (
            <Elements stripe={stripePromise} options={options}>
                <Payments setSelectedPlanValue={setSelectedPlanValue}
                          isClientSecret={isClientSecretLoading}
                          selectedPlan={selectedPlan}
                          setSelectedPlan={setSelectedPlan}
                />
            </Elements>
        )
    }
}
export default PaymentsWrapper;
