import React, { useState, useEffect } from "react";
import { Box, Typography, Stepper, Step, StepLabel, StepContent, Button } from "@mui/material";
import FormField from "../../components/FormComponent/FormField/FormField";
import styles from "./styles/styles.module.css";
import { clinicDetails } from "../../JsonForms/ClinicDetails";
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import ImageUploadModal from "./ImageUploadModal";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import cogoToast from "cogo-toast";
import axios from './../../utils/request';

const validationSchema = yup.object({
  openingTime: yup
    .string()
    .required('Opening time is required')
    .test('is-before-closing', 'Opening time must be before closing time', function (value) {
      const { closingTime } = this.parent;
      if (closingTime && value) {
        return new Date(`1970-01-01T${value}Z`) < new Date(`1970-01-01T${closingTime}Z`);
      }
      return true;
    }),

  closingTime: yup
    .string()
    .required('Closing time is required')
    .test('is-after-opening', 'Closing time must be after opening time', function (value) {
      const { openingTime } = this.parent;
      if (openingTime && value) {
        return new Date(`1970-01-01T${value}Z`) > new Date(`1970-01-01T${openingTime}Z`);
      }
      return true;
    }),

  daysOfWeek: yup.array().min(1, 'At least one day should be selected'),
  initialAssessmentDuration: yup.number().positive('Duration must be a positive number').required('Initial assessment duration is required'),
  initialAssessmentPrice: yup.number().positive('Price must be a positive number').required('Initial assessment price is required'),
  followUpTreatmentDuration: yup.number().required('This field is required'),
  followUpTreatmentPrice: yup.number().required('This field is required'),
  availableParking: yup.array().min(1, 'Select at least one parking option').required('Parking options are required'),
  accessibleAccess: yup.string().required('Accessible access selection is required'),
  insuranceAccepted: yup.array().min(1, 'Select at least one insurance').required('At least one insurance must be selected'),
  clinicDescription: yup.string().max(1500, 'Description cannot exceed 1500 characters'),
});

const ClinicDetails = ({ goToStep, clinicRegistrationData = {} }) => {
  const [clinicDetailsData, setClinicDetailsData] = useState(clinicDetails || { sections: [], actions: [] });
  const [src, setSrc] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentField, setCurrentField] = useState(null);

  const { register, handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleLinkButtonClick = (field, parentFieldGroup, subFieldIndex) => {
    if (field.action === "addInitialAssessmentType" || field.action === "addFollowUpTreatmentType") {
      const updatedClinicDetails = { ...clinicDetailsData };

      updatedClinicDetails.sections.forEach((section) => {
        section.fields.forEach((fieldGroup) => {
          if (fieldGroup === parentFieldGroup && fieldGroup.fields) {
            const dynamicField = fieldGroup.fields.find(
              (f) => f.dynamic && f.dynamicAction === field.action
            );

            if (dynamicField) {
              const dynamicFieldsCount = fieldGroup.fields.filter(
                (f) => f.dynamic && f.dynamicAction === field.action
              ).length;

              const newFieldId = `${dynamicField.id}-${dynamicFieldsCount + 1}`;
              const newField = {
                ...dynamicField,
                isDynamic: true,
                id: newFieldId,
                deleteId: newFieldId,
                label: undefined,
              };

              fieldGroup.fields.splice(subFieldIndex, 0, newField);

              setClinicDetailsData(updatedClinicDetails);
            }
          }
        });
      });
    } else if (field.action === "addTimeSlot") {
      const updatedClinicDetails = { ...clinicDetailsData };

      updatedClinicDetails.sections.forEach((section) => {
        section.fields.forEach((fieldGroup) => {
          if (fieldGroup.id === "addTimeSlotId" && fieldGroup.fields) {
            const dynamicFieldsCount = fieldGroup.fields.filter(
              (f) => f.group === "timeSlot"
            ).length;

            const newTimeSlotGroup = {
              group: "timeSlot",
              deleteId: `timeSlot-${dynamicFieldsCount + 1}`,
              fields: [
                {
                  placeholder: "Opening time",
                  type: "time",
                  required: true,
                  id: `openingTime-${dynamicFieldsCount + 1}`,
                },
                {
                  placeholder: "Closing time",
                  type: "time",
                  required: true,
                  id: `closingTime-${dynamicFieldsCount + 1}`,
                },
              ],
            };

            const newWeekdaySelector = {
              type: "no-border-checkbox",
              options: [{
                "label": "Mon",
                "value": "Mon"
              },
              {
                "label": "Tue",
                "value": "Tue"
              },
              {
                "label": "Wed",
                "value": "Wed"
              },
              {
                "label": "Thu",
                "value": "Thu"
              },
              {
                "label": "Fri",
                "value": "Fri"
              },
              {
                "label": "Sat",
                "value": "Sat"
              },
              {
                "label": "Sun",
                "value": "Sun"
              }],
              required: true,
              id: `daysOfWeek-${dynamicFieldsCount + 1}`,
              deleteId: `timeSlot-${dynamicFieldsCount + 1}`,
              isDynamic: true,
              values: {
                Mon: false,
                Tue: false,
                Wed: false,
                Thu: false,
                Fri: false,
                Sat: false,
                Sun: false,
              },
            };

            fieldGroup.fields.push(newTimeSlotGroup);
            fieldGroup.fields.push(newWeekdaySelector);

            setClinicDetailsData(updatedClinicDetails);
          }
        });
      });
    } else if (field.action === "handleImageChange") {
      setCurrentField(field);
      setSrc(field.src || null);
      setOpen(true);
    }

  };

  const handleDeleteField = (parentFieldGroup, subFieldIndex, fieldId) => {
    const updatedClinicDetails = { ...clinicDetailsData };

    updatedClinicDetails.sections.forEach((section) => {
      section.fields.forEach((fieldGroup) => {
        if (fieldGroup === parentFieldGroup && Array.isArray(fieldGroup.fields)) {
          fieldGroup.fields = fieldGroup.fields.filter(field => field.deleteId !== fieldId);
        }
      });
    });

    setClinicDetailsData(updatedClinicDetails);
  };
  const handleImageSave = (croppedImageUrl) => {
    if (currentField) {
      currentField.src = croppedImageUrl;
    }
    setSrc(croppedImageUrl);
    setOpen(false);
  };

  const onSubmit = async (data) => {
    if (Object.keys(clinicRegistrationData).length === 0) {
      cogoToast.error("Please register your clinic first");
      return;
    }
    const openingHours = [];
    const assessmentType = [];
    const followUpTreatmentType = [];

    Object.keys(data).forEach((key) => {
      if (key.startsWith('assessmentType')) {
        assessmentType.push(data[key]);
      }
      if (key.startsWith('followUpTreatmentType')) {
        followUpTreatmentType.push(data[key]);
      }
    });

    Object.keys(data).forEach((key) => {
      const Timingmatch = key.match(/^openingTime(\d+)$/);
      if (Timingmatch) {
        const index = Timingmatch[1];
        const openingTime = data[`openingTime-${index}`];
        const closingTime = data[`closingTime-${index}`];
        const daysOfWeek = data[`daysOfWeek-${index}`];

        if (openingTime && closingTime && daysOfWeek) {
          openingHours.push({
            days: daysOfWeek,
            from: openingTime,
            to: closingTime,
          });
        }
      }
    });

    if (data.openingTime && data.closingTime && data.daysOfWeek) {
      openingHours.push({
        days: data.daysOfWeek,
        from: data.openingTime,
        to: data.closingTime,
      });
    }
    const email = localStorage.getItem("LPCurrentUserEmail")
    const apiData = {
      btn_submit: true,
      provideruid: clinicRegistrationData.provideruid,
      uid: clinicRegistrationData.provideruid,
      listings: [
        {
          title: clinicRegistrationData.title,
          email: email,
          description: data.clinicDescription,
          health_insurances_accepted: data.insuranceAccepted,
          opening_hours: openingHours,
          parking: data.availableParking,
          initial_appointment_cost: data.initialAssessmentPrice,
          initial_appointment_time: data.initialAssessmentDuration,
          subsequent_appointment_cost: data.followUpTreatmentPrice,
          subsequent_appointment_time: data.followUpTreatmentDuration,
          accessible_access: data.accessibleAccess,
          address_1: clinicRegistrationData.address_1,
          address_2: clinicRegistrationData.address_2 || '',
          location: clinicRegistrationData.location,
          num_workers: clinicRegistrationData.num_workers,
          postcode: clinicRegistrationData.postcode,
          specialization: clinicRegistrationData.specialization,
          telephone_1: clinicRegistrationData.telephone_1,
          treatments: clinicRegistrationData.treatments,
          booking_management_software: clinicRegistrationData.booking_management_software,
          logo: src,
          assessment_type: assessmentType,
          followup_treatment_type: followUpTreatmentType,
        },
      ],
    };
    const response = await axios.post('/api/signup/2/', apiData);
    if (response?.data?.success) {
      localStorage.setItem("currentCompleted", 2);
      localStorage.setItem("LPCurrentProvideruid", clinicRegistrationData.provideruid);
      localStorage.setItem("LPCurrentClinicTitle", clinicRegistrationData.title);
      goToStep(2);
    } else {
      cogoToast.error(response?.data?.data);
    }
  }

  return (
    <Box className={styles.formPageContainer}>
      <Typography variant="gotham2XL" className={styles.title}>
        {clinicDetails.pageTitle}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stepper orientation="vertical">
          {clinicDetails.sections.map((section, index) => (
            <Step key={index} active>
              <StepLabel sx={{
                '& .MuiStepIcon-root': { color: 'white !important', border: '1px solid black', borderRadius: '50%' },
                '& .MuiStepIcon-text': { fill: 'black' },
              }}>
                <Typography variant="gothamLarge" className={`${styles.stepper} f-500`}>
                  {section.sectionName}
                </Typography>
                {section.introText && (
                  <Typography variant="gothamMedium" className={styles.stepper}>
                    {section.introText}
                  </Typography>
                )}
              </StepLabel>

              <StepContent>
                {section.fields.map((field, fieldIndex) => (
                  <Box key={fieldIndex} className={`${styles.stepper} margin-top-15`}>
                    {field.label && (
                      <Typography variant="gothamMedium" className="f-500">
                        {field.label}
                      </Typography>
                    )}
                    <Box>
                      {field.fields ? (
                        field.fields.map((subField, subFieldIndex) => {
                          if (subField.group) {
                            return (
                              <Box key={subFieldIndex} className={styles.formFields}>
                                {subField.fields.map((groupedField, groupedFieldIndex) => (
                                  <Box key={groupedFieldIndex} style={{ flex: "1" }}>
                                    {groupedField.label && (
                                      <Typography variant="gothamRegular">
                                        {groupedField.label}
                                      </Typography>
                                    )}
                                    <FormField
                                      field={groupedField}
                                      onLinkButtonClick={(clickedField) =>
                                        handleLinkButtonClick(clickedField, field, subFieldIndex)
                                      }
                                      register={register}
                                      control={control}
                                      errors={errors}
                                    />
                                    {groupedField.isDynamic && (
                                      <Button
                                        variant="outlined"
                                        color="error"
                                        size="small"
                                        onClick={() => handleDeleteField(field, subFieldIndex, subField.deleteId)}
                                      >
                                        Delete
                                      </Button>
                                    )}
                                  </Box>
                                ))}
                              </Box>
                            );
                          }
                          return (
                            <Box key={subFieldIndex}>
                              {subField.label && (
                                <Typography variant="gothamRegular" className="margin-top-30" style={{ marginRight: '10px' }}>
                                  {subField.label}
                                </Typography>
                              )}
                              <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <FormField
                                  field={subField}
                                  onLinkButtonClick={(clickedField) =>
                                    handleLinkButtonClick(clickedField, field, subFieldIndex)
                                  }
                                  register={register}
                                  control={control}
                                  errors={errors}
                                />

                                {subField.isDynamic && (
                                  <Button
                                    color="error"
                                    size="small"
                                    onClick={() => handleDeleteField(field, subFieldIndex, subField.deleteId)}
                                  >
                                    <RemoveCircleOutlineOutlinedIcon />
                                  </Button>
                                )}
                              </Box>
                            </Box>
                          );
                        })
                      ) : (
                        <FormField
                          field={field}
                          className={styles.formFieldContainer}
                          onLinkButtonClick={(clickedField) =>
                            handleLinkButtonClick(clickedField, section.fields, fieldIndex)
                          }
                          register={register}
                          control={control}
                          errors={errors}
                        />
                      )}

                    </Box>
                  </Box>
                ))}
              </StepContent>
            </Step>
          ))}
        </Stepper>
        <Box className={styles.submitButtonContainer}>
          {clinicDetails.actions.map((action, actionIndex) => (
            <Button
              key={actionIndex}
              type='submit'
              className={action.variant === 'primary' ? styles.primaryButton : styles.secondaryButton}
              size="large"
            >
              {action.label}
            </Button>
          ))}
        </Box>
      </form>
      <ImageUploadModal open={open} onClose={handleClose} src={src} setSrc={setSrc} handleImageSave={handleImageSave} />
    </Box>
  );
};

export default ClinicDetails;
