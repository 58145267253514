import React, { useState, useRef } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Box, IconButton, Link } from "@mui/material";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Add as AddIcon, Remove as RemoveIcon, Close as CloseIcon } from "@mui/icons-material";
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import styles from "./styles/imageUploadModal.module.css";

const ImageUploadModal = ({ open, onClose, src, setSrc, handleImageSave }) => {
    const [crop, setCrop] = useState({ unit: '%', x: 0, y: 0, width: 100, height: 100, aspect: 1 });
    const [zoom, setZoom] = useState(1);
    const [error, setError] = useState("");

    const fileInputRef = useRef(null);

    const openFileDialog = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setError("");
            if (file.size > 5 * 1024 * 1024) {
                setError("File size exceeds 5 MB. Please upload a smaller image.");
                return;
            }

            // Check file type (PNG or JPEG only)
            const fileType = file.type;
            if (fileType !== "image/png" && fileType !== "image/jpeg") {
                setError("Invalid file type. Only PNG and JPEG images are allowed.");
                return;
            }
            const reader = new FileReader();
            reader.onload = (event) => {
                setSrc(event.target.result);
                setCrop({ unit: '%', x: 0, y: 0, width: 100, height: 100, aspect: 1 });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleZoom = (factor) => {
        setZoom((prevZoom) => Math.min(3, Math.max(0.5, prevZoom + factor)));
    };
    const getCroppedImage = async (imageSrc, crop) => {
        const image = new Image();
        image.src = imageSrc;

        return new Promise((resolve, reject) => {
            image.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                if (!ctx || !crop.width || !crop.height) {
                    reject("Invalid crop dimensions.");
                    return;
                }

                // Get the actual image size
                const naturalWidth = image.naturalWidth;
                const naturalHeight = image.naturalHeight;

                // Scale crop dimensions from percentage to actual pixels
                const cropX = (crop.x / 100) * naturalWidth;
                const cropY = (crop.y / 100) * naturalHeight;
                const cropWidth = (crop.width / 100) * naturalWidth;
                const cropHeight = (crop.height / 100) * naturalHeight;

                // Set canvas size to match the cropped image size
                canvas.width = cropWidth;
                canvas.height = cropHeight;

                // Draw the cropped image onto the canvas
                ctx.drawImage(
                    image,
                    cropX,
                    cropY,
                    cropWidth,
                    cropHeight,
                    0,
                    0,
                    cropWidth,
                    cropHeight
                );

                // Convert canvas to Base64
                const base64String = canvas.toDataURL("image/jpeg"); // Output as Base64
                resolve(base64String); // Return Base64
            };

            image.onerror = (error) => reject(`Image loading error: ${error}`);
        });
    };

    const handleSave = async () => {
        if (!crop.width || !crop.height) {
            handleImageSave(src);  // Save original image if no crop
        } else {
            const base64Image = await getCroppedImage(src, crop);
            handleImageSave(base64Image);  // Save Base64 string
            setSrc(base64Image); // Store in state
        }
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} className={styles.modalContainer} sx={{
            "& .MuiDialog-paper": {
                maxWidth: "830px !important", maxHeight: "700px !important", borderRadius: "14px !important"
            }
        }}>
            <Box className={styles.headerLinks}>
                <Link className={styles.closeButton} onClick={openFileDialog}>
                    Upload different photo <BorderColorOutlinedIcon className={styles.closeIcon} />
                </Link>
                <Link className={styles.closeButton} onClick={onClose}>
                    Close <CloseIcon className={styles.closeIcon} />
                </Link>
            </Box>
            <DialogTitle className={styles.header}>
                <Typography variant="gothamMedium" className={styles.title}>Upload clinic logo</Typography>
                <Typography variant="gothamRegular" className={styles.subtitle}>
                    Upload a valid logo file in JPG or PNG format, and ensure the file size is under 5 MB.
                </Typography>
            </DialogTitle>
            <DialogContent className={styles.content}>
                <Box className={styles.container}>
                    {/* Header */}
                    <Typography variant="gothamRegular" className={styles.headerText}>Crop and scale</Typography>
                    {/* Crop Container */}
                    <Box className={styles.cropBox}>
                        <input
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                        />
                        {src ? (
                            <Box className={styles.imageContainer}>
                                <ReactCrop
                                    crop={crop}
                                    onChange={(newCrop) => setCrop(newCrop)}
                                    aspect={1}
                                    onComplete={(c) => console.log("Completed crop:", c)}

                                >
                                    <img
                                        src={src}
                                        alt="Crop"
                                        style={{
                                            objectFit: "cover",
                                            transform: `scale(${zoom})`,
                                        }}
                                    />
                                </ReactCrop>
                                {/* Zoom Controls Overlay */}
                                <Box className={styles.zoomControls}>
                                    <IconButton
                                        className={styles.zoomButton}
                                        onClick={() => handleZoom(0.1)}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                    <IconButton
                                        className={styles.zoomButton}
                                        onClick={() => handleZoom(-0.1)}
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        ) : (
                            // Upload box before image selection
                            <Box className={styles.uploadBox} onClick={openFileDialog}>
                                <Typography className={styles.uploadText}>
                                    Click to upload image
                                </Typography>

                            </Box>
                        )}
                    </Box>
                    {error && (
                        <Typography color="error" variant="body2" className={styles.errorText}>
                            {error}
                        </Typography>
                    )}
                </Box>
            </DialogContent>
            <DialogActions className="justify-center">
                <Button variant="contained" className={styles.doneButton} onClick={handleSave}>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImageUploadModal;
