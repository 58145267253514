export const clinicDetails = {
  "pageTitle": "Clinic Details",
  "type": "form",
  "sections": [
    {
      "sectionName": "Appointment Prices",
      "sectionNo": 1,
      "fields": [
        {
          "label": "Initial assessment",
          "fields": [
            {
              "group": "assessment",
              "fields": [
                {
                  "label": "Enter duration",
                  "placeholder": "e.g. 30",
                  "unit": "mins",
                  "type": "number-unit",
                  "id": "initialAssessmentDuration"
                },
                {
                  "label": "Enter price",
                  "placeholder": "e.g. 50",
                  "unit": "£",
                  "type": "number-unit",
                  "id": "initialAssessmentPrice"
                }
              ]
            },
            {
              "label": "Select assessment type (optional)",
              "placeholder": "Select an option",
              "type": "dropdown",
              "options": [
                "Home visit",
                "Virtual visit",
                "Consultant physio",
                "Senior physio",
              ],
              "dynamic": true,
              "dynamicAction": "addInitialAssessmentType",
              "id": "assessmentType"
            },
            {
              "linkTitle": "+ Add additional initial assessment type",
              "type": "link-button",
              "action": "addInitialAssessmentType",
              "id": "addInitialAssessmentTypeLink"
            }
          ]
        },
        {
          "label": "Follow-up treatment",
          "fields": [
            {
              "group": "treatment",
              "fields": [
                {
                  "label": "Enter duration",
                  "unit": "mins",
                  "placeholder": "e.g. 30",
                  "type": "number-unit",
                  "id": "followUpTreatmentDuration"
                },
                {
                  "label": "Enter price",
                  "unit": "£",
                  "placeholder": "e.g. 30",
                  "type": "number-unit",
                  "id": "followUpTreatmentPrice"
                }
              ]
            },
            {
              "label": "Select follow-up treatment type (optional)",
              "placeholder": "Select an option",
              "type": "dropdown",
              "options": [
                "Home visit",
                "Virtual visit",
                "Consultant physio",
                "Senior physio",
              ],
              "dynamic": true,
              "id": "followUpTreatmentType",
              "dynamicAction": "addFollowUpTreatmentType",

            },
            {
              "linkTitle": "+ Add additional follow-up treatment type",
              "type": "link-button",
              "action": "addFollowUpTreatmentType",
              "id": "addFollowUpTreatmentTypeLink"
            }
          ]
        }
      ]
    },
    {
      "sectionName": "Opening Times",
      "sectionNo": 2,
      "introText": "Add time slot(s) and assign them to days",
      "fields": [
        {
          "type": "group",
          "id": "addTimeSlotId",
          "dynamicAction": "addTimeSlot",
          "fields": [
            {
              "group": "timeSlot",
              "fields": [
                {
                  "placeholder": "Opening time",
                  "type": "time",
                  "id": "openingTime"
                },
                {
                  "placeholder": "Closing time",
                  "type": "time",
                  "id": "closingTime"
                }
              ]
            },
            {
              "type": "no-border-checkbox",
              "options": [
                {
                  "label" : "Mon",
                  "value" : "Mon"
                },
                {
                  "label" : "Tue",
                  "value" : "Tue"
                },
                {
                  "label" : "Wed",
                  "value" : "Wed"
                },
                {
                  "label" : "Thu",
                  "value" : "Thu"
                },
                {
                  "label" : "Fri",
                  "value" : "Fri"
                },
                {
                  "label" : "Sat",
                  "value" : "Sat"
                },
                {
                  "label" : "Sun",
                  "value" : "Sun"
                },
              ],
              "id": "daysOfWeek"
            }
          ]
        },
        {
          "linkTitle": "+Add time slot",
          "type": "link-button",
          "action": "addTimeSlot",
          "id": "addTimeSlotLink"
        }
      ]
    },
    {
      "sectionName": "Available Parking",
      "sectionNo": 3,
      "introText": "Select parking options available for patients",
      "fields": [
        {
          "type": "small-checkbox",
          "width": "177px",
          "options": [
            "Onsite Parking",
            "Street Parking",
            "No Parking"
          ],
          "id": "availableParking"
        }
      ]
    },
    {
      "sectionName": "Accessible Access",
      "sectionNo": 4,
      "introText": "Is accessible access available for patients?",
      "fields": [
        {
          "type": "radio",
          "options": [
            "Yes",
            "No"
          ],
          "id": "accessibleAccess"
        }
      ]
    },
    {
      "sectionName": "Insurance Accepted",
      "sectionNo": 5,
      "introText": "Select accepted insurance companies",
      "fields": [
        {
          "type": "checkbox-image",
          "options": [
            { "Bupa": "Bupa.png" },
            { "AXA": "AXA.png" },
            { "Aviva": "Aviva.png" },
            { "Allianz": "Allianz.png" },
            { "WPA": "WPA.png" },
            { "Vitality": "Vitality.png" },
            { "Freedom": "Freedom.png" },
            { "The Exeter": "TheExeter.png" },
            { "Cigna": "Cigna.jpeg" }
          ],
          "id": "insuranceAccepted"
        },
        {
          "label": "Haven’t found an insurer? Add it manually below.",
          "type": "text",
          "id": "manualInsuranceEntry"
        }
      ]
    },
    {
      "sectionName": "Clinic Description",
      "sectionNo": 6,
      "introText": "Add clinic description",
      "fields": [
        {
          "type": "textarea",
          "rows": 10,
          "placeholder": "Description",
          "id": "clinicDescription"
        }
      ]
    },
    {
      "sectionName": "Business Logo",
      "sectionNo": 7,
      "introText": "Add clinic logo",
      "fields": [
        {
          "type": "file",
          "id": "clinicLogo",
          "action": "handleImageChange"
        }
      ]
    }
  ],
  "actions": [
    {
      "label": "Continue",
      "type": "button",
      "action": "submit",
      "variant": "primary",
      "id": "continueButton"
    }
  ]
}
