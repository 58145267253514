import axios from 'axios';

export const axiosProxyInstance = axios.create({
    baseURL: process.env.REACT_APP_API_PROXY_URL,
});

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Add a response interceptor to handle 301 redirections
axiosInstance.interceptors.response.use(
    (response) => {
        // If the response is successful, return it as is
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        // Check if the error is due to a 301 redirection
        if (error.response && error.response.status === 301) {
            const redirectUrl = error.response.headers.location;

            // Update the original request URL to the redirect URL
            originalRequest.url = redirectUrl;

            // Retry the request with the new URL
            return axiosInstance(originalRequest);
        }

        // Reject the promise for other errors
        return Promise.reject(error);
    }
);

export default axiosInstance;
