import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  InputAdornment
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios, { axiosProxyInstance } from './../../utils/request';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './styles.module.css';
import cogoToast from "cogo-toast";
import SignupInfoSection from './SignupInfoSection';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const schema = yup.object().shape({
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  phone: yup
    .string()
    .required('Phone number is required')
    .test('isValidPhone', 'Phone number must be exactly 10 digits', (value, context) => {
      if (!value) return false;

      const digitsOnly = value.replace(/\D/g, '');
      const country = context.parent.selectedCountry;

      if (!country || !country.dialCode) return false;

      const countryCode = country.dialCode;
      const localNumber = digitsOnly.startsWith(countryCode)
        ? digitsOnly.slice(countryCode.length)
        : digitsOnly;
      return localNumber.length === 10;
    }),
  password: yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
  //   cfpassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required('Confirm password is required'),
  terms: yup.boolean().oneOf([true], 'You must accept the Terms & Conditions').required('You must accept the Terms & Conditions'),
});


const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [phone, setPhone] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const currentUserId = localStorage.getItem("LPCurrentUserId");
    if (currentUserId) {
      setIsUserLoggedIn(true);
      navigate('/onboard');
    } else {
      setIsUserLoggedIn(false);
    }
  }, [navigate]);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const getTokenResponse = await axiosProxyInstance.get('/signupToken/');
      const token = getTokenResponse.data?.data;
      const formData = {
        btn_submit: true,
        cfpassword: data.password,
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        password: data.password,
        phone,
        role: 'practitioner',
        sec_key: token,
        title: 'Dr',
        toc_accept: 1,
      };
      const signUpResponse = await axios.post('/api/signup/', formData);
      if (signUpResponse?.data?.success) {
        localStorage.setItem("LPCurrentUserId", signUpResponse?.data?.data?.ID)
        localStorage.setItem("LPCurrentUserEmail", data.email)
        localStorage.setItem("LPCurrentUserName", `${formData.firstname} ${formData.lastname}`);
        navigate('/onboard');
      } else {
        cogoToast.error(<div dangerouslySetInnerHTML={{ __html: signUpResponse?.data?.data?.errors?.[0] }} />);
      }
    } catch (error) {
      console.error('Error during signup:', error);
    } finally {
      setLoading(false);
    }
  };
  if (isUserLoggedIn) {
    return <Box className="flex justify-center align-center margin-top-40">Redirecting.....</Box>;
  }
  return (
    <>
      <Box className={styles.signupContainer}>
        <Box className={styles.leftSection}>
          <Typography variant="gotham5XL" className={styles.mainTitle}>
            Reach 100s of new patients in your area
          </Typography>
          <Typography variant="gothamMedium" className={styles.subTitle}>
            Attract new patients and grow your business!
          </Typography>
        </Box>

        <Box className={styles.rightSection}>
          <Box className={styles.signupForm}>
            <Typography variant="gothamXL" className={styles.formTitle}>
              Create an account to list your clinic
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box className={styles.nameContainer}>
                <TextField
                  label="First name"
                  fullWidth
                  {...register('firstname')}
                  error={!!errors.firstname}
                  helperText={errors.firstname?.message}
                  className={styles.inputField}
                />
                <TextField
                  label="Surname"
                  fullWidth
                  {...register('lastname')}
                  error={!!errors.lastname}
                  helperText={errors.lastname?.message}
                  className={styles.inputField}
                />
              </Box>
              <TextField
                label="Email"
                fullWidth
                {...register('email')}
                error={!!errors.email}
                helperText={errors.email?.message}
                className={styles.inputField}
              />
              <Box className={styles.phoneInputContainer}>
                <PhoneInput
                  country={'gb'}
                  value={phone}
                  onChange={(value, country) => {
                    setPhone(value);
                    setValue("phone", value, { shouldValidate: true });
                    setValue("selectedCountry", country);
                  }}
                  inputProps={{
                    name: 'phone',
                    required: true,
                  }}
                  containerClass={styles.phoneInputWrapper}
                  inputClass={styles.phoneInputBox}
                />


                {errors.phone && (
                  <Typography variant='gothamSmall' color="error" className={styles.errorText}>
                    {errors.phone.message}
                  </Typography>
                )}
              </Box>
              <Box className={styles.passwordContainer}>
                <TextField
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  {...register('password')}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  className={styles.inputField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <FormControlLabel
                control={<Checkbox {...register('terms')} error={!!errors.terms} />}
                label={<Typography variant='gothamSmall'>I agree to the <Link className={styles.toc}>Terms & Conditions</Link></Typography>}
              />
              {errors.terms && (
                <Typography variant='gothamSmall' color="error" className={styles.errorText}>
                  {errors.terms.message}
                </Typography>
              )}
              <FormControlLabel
                control={<Checkbox />}
                label={<Typography variant='gothamSmall'>I would NOT like to receive latest updates from Local Physio</Typography>}
                className={styles.checkbox}
              />

              <Button variant="contained" className={styles.submitButton} type="submit" disabled={loading}>
                {loading ? 'Creating Account' : 'Create account'}
              </Button>
            </form>
          </Box >
        </Box >
      </Box >
      <SignupInfoSection />
    </>
  );
};

export default Signup;
