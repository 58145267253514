export const clinicRegistration = {
  "pageTitle": "Clinic Registration",
  "type": "registration",
  "sections": [
    {
      "sectionName": "Add a Clinic",
      "sectionNo": 1,
      "introText": "You will be able to add additional clinics later.",
      "fields": [
        {
          "placeholder": "Clinic name",
          "type": "text",
          "id": "clinicName"
        },
        {
          "placeholder": "Street Address",
          "type": "text",
          "id": "streetAddress"
        },
        {
          "placeholder": "Building name / Suite / Unit etc. (optional)",
          "type": "text",
          "required": false,
          "id": "buildingName"
        },
        {
          "group": "address",
          "fields": [
            {
              "placeholder": "Town/City",
              "type": "dropdown",
              "action": "searchCity",
              "id": "town",
              "options": ""
            },
            {
              "placeholder": "Postcode",
              "type": "text",
              "id": "postcode"

            },
          ]
        },
        {
          "placeholder": "Phone no. for bookings",
          "type": "text",
          "id": "phone"
        }
      ]
    },
    {
      "sectionName": "Number of Practising Physiotherapists",
      "sectionNo": 2,
      "introText": "How many physiotherapists practise out of here?",
      "fields": [
        {
          "label": "How many physiotherapists practice out of here?",
          "type": "radio",
          "options": [
            "1-2",
            "3-4",
            "5+"
          ],
          "id": "physiotherapistsCount"
        }
      ]
    },
    {
      "sectionName": "Area of Speciality",
      "sectionNo": 3,
      "introText": "Please select the relevant specialities, services and offered treatments for your clinic",
      "fields": [
        {
          "label": "Please select the relevant specialities, services, and offered treatments for your clinic",
          "type": "checkbox",
          "action": "areaOfSpeciality",
          "id": "areaOfSpeciality",
          "options": {
            "Musculoskeletal Physiotherapy": {
              "label": "Additional Services",
              "introText": "Please remove any that don’t apply to your clinic:",
              "type": "multiselect",
              "id": "musculoskeletalAdditionalServices",
              "options": [
                "Strains & Sprains",
                "Joint Pain",
                "Arthritis",
                "Back & Neck Pain",
                "Sciatica",
                "Whiplash",
                "Headaches",
                "Knee Replacement",
                "Hip Replacement",
                "Fractures and Dislocations",
                "Rehabilitation After Surgery",
                "Achilles Tendinitis"
              ]
            },
            "Sports Physiotherapy": {
              "label": "Additional Services",
              "introText": "Please remove any that don’t apply to your clinic:",
              "type": "multiselect",
              "required": false,
              "id": "sportsAdditionalServices",
              "options": ["Sports Injury Physiotherapy"]
            },
            "Women’s Health": {
              "label": "Additional Services",
              "introText": "Please remove any that don’t apply to your clinic:",
              "type": "multiselect",
              "required": false,
              "id": "womensHealthAdditionalServices",
              "options": ["Pelvic Floor & Incontinence", "Antenatal & Postnatal Care"]
            },
            "Neurological Physiotherapy": {
              "label": "Additional Services",
              "introText": "Please remove any that don’t apply to your clinic:",
              "type": "multiselect",
              "required": false,
              "id": "neurologicalAdditionalServices",
              "options": [
                "Stroke Physiotherapy",
                "Multiple Sclerosis Physiotherapy",
                "Parkinson's Physiotherapy",
                "Head Injury Physiotherapy"
              ]
            },
            "Respiratory Physiotherapy": {
              "label": "Additional Services",
              "introText": "Please remove any that don’t apply to your clinic:",
              "type": "multiselect",
              "required": false,
              "id": "respiratoryAdditionalServices",
              "options": []
            },
            "Home Visits": {
              "label": "Additional Services",
              "introText": "Please remove any that don’t apply to your clinic:",
              "type": "multiselect",
              "required": false,
              "id": "homeVisitsAdditionalServices",
              "options": []
            },
            "Services": {
              "label": "Additional Services",
              "introText": "Please remove any that don’t apply to your clinic:",
              "type": "multiselect",
              "required": false,
              "id": "servicesAdditionalServices",
              "options": [
                "Pain Management",
                "Medico-Legal Experts",
                "Hand Therapy",
                "Ergonomic Assessment",
                "Pilates",
                "Hydrotherapy",
                "Video Motion Analysis",
                "Rehabilitation Studio"
              ]
            },
            "Treatments": {
              "label": "Additional Services",
              "introText": "Please remove any that don’t apply to your clinic:",
              "type": "multiselect",
              "required": false,
              "id": "treatmentsAdditionalServices",
              "options": [
                "Acupuncture & Dry Needling",
                "Massage Therapy",
                "Shockwave Therapy",
                "Joint Injections",
                "Ultrasound",
                "Epley Manoeuvre (BPPV / Vertigo)"
              ]
            },
            "Age Specialisms": {
              "label": "Additional Services",
              "introText": "Please remove any that don’t apply to your clinic:",
              "type": "multiselect",
              "required": false,
              "id": "ageSpecialismsAdditionalServices",
              "options": [
                "Babies & Toddlers Physiotherapy (0-2)",
                "Young Children Physiotherapy (3-9)",
                "Older Children Physiotherapy (10-17)",
                "Adults Physiotherapy (18+)",
                "Elderly Care Physiotherapy"
              ]
            }
          },
          "required": false
        }
      ]
    },
    {
      "sectionName": "Booking Management Software",
      "sectionNo": 4,
      "introText": "Type in the name of software used by your clinic.",
      "fields": [
        {
          "placeholder": "Software name",
          "type": "text",
          "required": false,
          "id": "software"
        }
      ]
    },
  ],
  "actions": [
    {
      "label": "Continue",
      "type": "button",
      "action": "submit",
      "variant": "primary"
    }
  ]
}