import React, { useState, useEffect } from "react";
import { Box, Stepper, Step, StepLabel, Link } from "@mui/material";
import ClinicRegistration from "./ClinicRegistration";
import ClinicDetails from "./ClinicDetails";
import Payments from "./Payments";
import styles from "./styles/styles.module.css";
import Logo from '../../app/assets/logo.png';
import { useLocation } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';

const OnboardingStepper = ({stripePromise}) => {
  const [step, setStep] = useState(0);
  const [savedStep, setSavedStep] = useState(0);
  const [clinicRegistrationData, setClinicRegistrationData] = useState({});

  const location = useLocation();
  useEffect(() => {
    let currentStep = parseInt(localStorage.getItem("currentCompleted"));
    if (isNaN(currentStep) && Object.keys(clinicRegistrationData).length > 0) {
      currentStep = 1;
    }
    if (!isNaN(currentStep)) {
      setSavedStep(currentStep);
      if (currentStep === 3) {
        if (!location.pathname.includes("/payment-success")) {
          localStorage.removeItem("currentCompleted");
          localStorage.removeItem("LPCurrentUserId");
          localStorage.removeItem("LPCurrentUserEmail");
        } else { return }
      } else {
        goToStep(currentStep);
      }
    }
  }, [step]);

  const goToStep = (stepNumber) => {
    setStep(stepNumber);
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return <ClinicRegistration goToStep={goToStep} setClinicRegistrationData={setClinicRegistrationData} />;
      case 1:
        return <ClinicDetails goToStep={goToStep} clinicRegistrationData={clinicRegistrationData} />;
      case 2:
        return <Payments clinicRegistrationData={clinicRegistrationData} stripePromise={stripePromise} />;
      default:
        return <ClinicRegistration goToStep={goToStep} />;
    }
  };

  const stepTitles = ["Clinic Registration", "Clinic Details", "Payment & Billing"];

  return (
    <Box className={styles.stepperContainer}>
      <Box className={styles.stepperSteps}>
        <Box className={styles.logoContainer}>
          <img src={Logo} alt="local-physio" height={18} width={150} />
        </Box>
        <Box className={styles.stepperWrapper}>
          <Stepper activeStep={step} className={styles.stepperWrapperStep}>
            {stepTitles.map((title, index) => (
              <Step
                key={index}
                onClick={() =>
                  index >= savedStep ? goToStep(index) : null
                }
                completed={index <= savedStep}
              >
                <StepLabel
                  StepIconComponent={() => null}
                  classes={{
                    label:
                      step === index
                        ? styles.stepLabelActive
                        : index < savedStep
                          ? styles.stepLabelCompleted
                          : styles.stepLabel,
                    iconContainer: styles.step,
                  }}
                >
                  <span className="flex justify-center">
                    <>
                      {index < savedStep ? (
                        <CheckIcon className={styles.checkIcon} />
                      ) : (
                        `${index + 1}.`
                      )}
                    </>
                    {step === index ? (
                      <span>&nbsp;{title}</span>
                    ) : <Box sx={{ display: { xs: "none", sm: "block" } }}>&nbsp;{title}</Box>}
                  </span>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Link sx={{ display: { xs: "none", sm: "block" } }} className={styles.saveExit} onClick={() => console.log("Save & Exit")}>
          Save & Exit
        </Link>
      </Box>
      <Box sx={{ marginTop: 4 }}>{renderStep()}</Box>
      <Link sx={{ display: { xs: "block", sm: "none" } }} className={`text-center margin-top-10 ${styles.saveExit}`} onClick={() => console.log("Save & Exit")}>
        Save & Exit
      </Link>
    </Box>
  );
};

export default OnboardingStepper;
