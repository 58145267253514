import React, { useState } from 'react';
import { Box, Typography, Stepper, Step, StepLabel, StepContent, Button } from "@mui/material";
import FormField from "../../components/FormComponent/FormField/FormField";
import styles from "./styles/styles.module.css";
import { clinicRegistration } from '../../JsonForms/ClinicRegistration';
import { axiosProxyInstance } from './../../utils/request';
import { useForm } from 'react-hook-form';
import cogoToast from "cogo-toast";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationSchema = yup.object({
  clinicName: yup
    .string()
    .matches(/^[a-zA-Z0-9\s,.&()-]+$/, "Clinic name cannot contain special characters")
    .max(100, "Clinic name cannot exceed 100 characters")
    .required("Clinic name is required"),
  streetAddress: yup.string().required('Street address is required'),
  buildingName: yup.string(),
  town: yup.object().required('Town/City is required'),
  postcode: yup.string().required('Postcode is required'),
  phone: yup
    .number()
    .typeError('Phone number must be a number')
    .test('len', 'Phone number must be 10 digits', (val) => val && val.toString().length === 10)
    .required('Phone number is required'),
  physiotherapistsCount: yup.string().required('Please select number of physiotherapists'),
  areaOfSpeciality: yup.array().min(1, 'At least one speciality is required').required('This field is required'),
  additionalServices: yup
    .array()
    .transform((value, originalValue) =>
      typeof originalValue === 'string' ? originalValue.split(',') : originalValue
    )
    .of(yup.string()),
  software: yup.string(),
});

const ClinicRegistration = ({ goToStep, setClinicRegistrationData }) => {
  const [options, setOptions] = useState(null);
  const [selectedValues, setSelectedValues] = useState({});

  const { register, handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });
  const handleLinkButtonClick = async (field, parentFieldGroup) => {
    if (parentFieldGroup.action === "searchCity") {
      try {
        const getLocationsResponse = await axiosProxyInstance.get(`/location?town=${field}`);
        const responseData = getLocationsResponse.data?.data;
        const options = responseData?.map((item) => ({ label: item.n, value: item?.id }));
        setOptions(options);
      } catch (error) {
        console.error('Error during signup:', error);
      } finally {
      }
    }
  }

  const onSubmit = async (data) => {
    const currentUserId = localStorage.getItem("LPCurrentUserId");
    if (currentUserId) {
      try {
        const combinedAdditionalServices = Object.values(selectedValues).flat();
        const combinedSpecialization = Object.keys(selectedValues);
        const apiData = {
          title: data.clinicName,
          address_1: data.streetAddress,
          address_2: data.buildingName || '',
          location: data.town?.value,
          num_workers: data.physiotherapistsCount,
          postcode: data.postcode,
          specialization: combinedSpecialization || [],
          telephone_1: data.phone,
          treatments: combinedAdditionalServices || [],
          provideruid: currentUserId,
          uid: currentUserId,
          booking_management_software: data.software,
        };

        setClinicRegistrationData(apiData)
        goToStep(1);
      } catch (error) {
        console.log(error)
      }
    } else {
      cogoToast.error(<div dangerouslySetInnerHTML={{ __html: `Create an account to onboard your clinic. Sign up <a href="/signup">here</a>!` }} />);
    }
  }

  return (
    <Box className={styles.formPageContainer}>
      <Typography variant="gotham2XL" className={styles.title}>
        {clinicRegistration.pageTitle}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stepper orientation="vertical">
          {clinicRegistration.sections.map((section, index) => (
            <Step key={index} active>
              <StepLabel
                sx={{
                  '& .MuiStepIcon-root': { color: 'white !important', border: '1px solid black', borderRadius: '50%' },
                  '& .MuiStepIcon-text': { fill: 'black' },
                }}>
                <Typography variant="gothamLarge" className={`${styles.stepper} f-500`}>
                  {section.sectionName}
                </Typography>
                {section.introText && (
                  <Typography variant="gothamMedium" className={styles.stepper}>
                    {section.introText}
                  </Typography>
                )}
              </StepLabel>
              <StepContent>
                <Box>
                  {section.fields.map((field, fieldIndex) => {
                    if (field.group) {
                      return (
                        <Box key={fieldIndex} className={`${styles.stepper} ${styles.formFields} margin-top-15`}>
                          {field.fields.map((groupedField, groupedFieldIndex) => (
                            <Box key={groupedFieldIndex} style={{ flex: "1" }}>
                              <FormField field={{ ...groupedField, options }} register={register} onLinkButtonClick={(clickedField) =>
                                handleLinkButtonClick(clickedField, groupedField, fieldIndex)
                              }
                                errors={errors}
                                control={control}
                              />
                            </Box>
                          ))}
                        </Box>
                      );
                    } else {
                      return (
                        <Box key={fieldIndex} className={`${styles.stepper} margin-top-15`}>
                          <FormField
                            field={field}
                            register={register}
                            onLinkButtonClick={(clickedField) =>
                              handleLinkButtonClick(clickedField, field, fieldIndex)
                            }
                            errors={errors}
                            selectedValues={selectedValues}
                            setSelectedValues={setSelectedValues}
                            control={control}
                          />
                        </Box>
                      );
                    }
                  })}
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        <Box className={styles.submitButtonContainer}>
          {clinicRegistration.actions.map((action, actionIndex) => (
            <Button
              key={actionIndex}
              className={action.variant === 'primary' ? styles.primaryButton : styles.secondaryButton}
              size="large"
              type="submit"
            >
              {action.label}
            </Button>
          ))}
        </Box>
      </form>
    </Box >
  );
};

export default ClinicRegistration;
