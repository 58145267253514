import React, {useEffect, useState} from "react";
import { Container, Typography, Box } from "@mui/material";
import styles from "./styles/success.module.css";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { useLocation, useNavigate } from "react-router-dom";

const PaymentSuccess = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { stripePromise } = props;

    useEffect(() => {
        const currentStep = parseInt(localStorage.getItem("currentCompleted"));

        if (location.pathname.includes("/payment-success")) {
            if (currentStep === 3) {
                localStorage.removeItem("currentCompleted");
                localStorage.removeItem("LPCurrentUserId");
                localStorage.removeItem("LPCurrentUserEmail");
                localStorage.removeItem("LPCurrentUserName");
                localStorage.removeItem("LPCurrentProvideruid");
                localStorage.removeItem("LPCurrentClinicTitle");
                navigate('/signup');
            } else if (currentStep === 2) {
                localStorage.setItem("currentCompleted", 3);
            } else {
                navigate('/signup');
            }
        }
    }, [location, navigate]);

    useEffect(() => {
        if (!stripePromise) return;

        stripePromise.then(async (stripe) => {
            const url = new URL(window.location);
            const clientSecret = url.searchParams.get('payment_intent_client_secret');
            const { error, paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
            console.log(paymentIntent);
        });
    }, [stripePromise]);

    return (
        <Container className={styles.container}>
            <Box className={styles.mainContent}>
                <Box className={styles.checkmark}><DoneOutlinedIcon className={styles.checkIcon} /></Box>
                <Typography variant="gotham5XL" className={styles.title}>
                    Payment successful
                </Typography>
                <Typography variant="gothamMediumLarge" className={styles.description}>
                    Thank you for completing your payment. <br />
                    Your clinic listing is almost live on Local Physio.
                </Typography>

                {/* What Happens Next */}
                <Box className={styles.stepsSection}>
                    <Typography variant="gothamXL" className={styles.stepsTitle}>
                        What happens next
                    </Typography>
                    <Box className={styles.stepsContainer}>
                        <Box className={styles.stepCard}>
                            <AccessTimeIcon />
                            <Typography variant="gothamMedium" className={styles.stepTitle}>
                                Review in progress
                            </Typography>
                            <Typography variant="gothamMedium" className={styles.stepText}>
                                Our team will review your clinic details within 2 business days.
                                We will contact you with any queries.
                            </Typography>
                        </Box>
                        <Box className={styles.stepCard}>
                            <CampaignOutlinedIcon />
                            <Typography variant="gothamMedium" className={styles.stepTitle}>
                                Go live
                            </Typography>
                            <Typography variant="gothamMedium" className={styles.stepText}>
                                You will be notified by email as soon as your clinic profile is listed.
                            </Typography>
                        </Box>
                        <Box className={styles.stepCard}>
                            <PersonAddAltOutlinedIcon />
                            <Typography variant="gothamMedium" className={styles.stepTitle}>
                                Manage your account
                            </Typography>
                            <Typography variant="gothamMedium" className={styles.stepText}>
                                Once live, you can add practitioners and update details.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* Additional Clinics */}
            <Box className={styles.footer}>
                <Typography variant="gotham2XL" className={styles.footerText}>
                    Have more clinics to add?
                </Typography>
                <Typography variant="gothamMediumLarge">
                    Email us at{" "}
                    <a href="mailto:info@local-physio.co.uk" className={styles.emailLink}>
                        info@local-physio.co.uk
                    </a>{" "}
                    for a multi-clinic discount.
                </Typography>
            </Box>
        </Container>
    );
};

export default PaymentSuccess;
