import React from "react";
import { Container, Typography, Box } from "@mui/material";
import styles from "./styles/success.module.css";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

const PaymentSuccess = () => {
    return (
        <Container className={styles.container}>
            <Box className={styles.mainContent}>
                <Box className={styles.checkmark}><DoneOutlinedIcon className={styles.checkIcon} /></Box>
                <Typography variant="gotham3XL" className={styles.title}>
                    Your progress has been saved
                </Typography>
                <Typography variant="gothamMediumLarge" className={styles.description}>
                    You can log in anytime to continue onboarding. Once you complete it, <br/>our team will review your clinic, and it will be listed on our platform.
                </Typography>
            </Box>
        </Container>
    );
};

export default PaymentSuccess;
