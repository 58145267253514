const MuiTypography = {
    defaultProps: {
      variantMapping: {
        gothamXS: 'p',
        gothamSmall: 'p',
        gothamRegular: 'p',
        gothamMedium: 'p',
        gothamLarge: 'p',
        gothamXL: 'h1',
        gotham2XL: 'h2',
        gotham3XL: 'h3',
        gotham4XL: 'h4',
      },
    },
  };
  
  export default MuiTypography;
  