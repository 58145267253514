import React from "react";
import { IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";
import styles from "./footer.module.css";
import Logo from "../../app/assets/footer-logo.png"

const Footer = () => {
    return (
        <footer className={styles.footerContainer}>
            <div className={styles.footerMain}>
                <Grid container spacing={4}>
                    <Grid size={{ xs: 12, md: 3 }}>
                        <div className={styles.footerLogoSection}>
                            <Typography variant="gothamLarge" className={styles.footerLogo}>
                                <img src={Logo} height={22} width={180} alt="footer-logo" />
                            </Typography>
                            <div className={styles.footerIcons}>
                                <IconButton className={styles.footerIcon} component="a" href="https://www.facebook.com/Local.Physio" target="_blank" rel="noopener noreferrer">
                                    <FacebookIcon />
                                </IconButton>
                                <IconButton className={styles.footerIcon} component="a" href="https://plus.google.com/112750790409872686548" target="_blank" rel="noopener noreferrer">
                                    <GoogleIcon />
                                </IconButton>
                                <IconButton className={styles.footerIcon} component="a" href="https://twitter.com/LocalPhysioUK" target="_blank" rel="noopener noreferrer">
                                    <TwitterIcon />
                                </IconButton>
                            </div>
                        </div>
                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }}>
                        <Typography variant="gothamMedium" className={styles.footerHeading}>
                            BROWSE BY AREA
                        </Typography>
                        <div className={styles.footerLinks}>
                            {[
                                "East England",
                                "London",
                                "North West England",
                                "South West England",
                                "Yorkshire and the Humber",
                                "Scotland",
                                "East Midlands",
                                "North East England",
                                "South East England",
                                "West Midlands",
                                "Northern Ireland",
                                "Wales",
                            ].map((area) => {
                                const formattedLink = `https://www.local-physio.co.uk/region/${area.toLowerCase().replace(/\s+/g, "-")}/`;

                                return (
                                    <Typography
                                        key={area}
                                        variant="gothamRegular"
                                        className={styles.footerLink}
                                        component="a"
                                        href={formattedLink}
                                        style={{ textDecoration: "none", color: "inherit", cursor: "pointer" }}
                                    >
                                        {area}
                                    </Typography>
                                );
                            })}
                        </div>
                    </Grid>
                    <Grid size={{ xs: 6, md: 2.5 }}>
                        <Typography variant="gothamMedium" className={styles.footerHeading}>
                            FOR PHYSIOTHERAPISTS
                        </Typography>
                        {[
                            { label: "About Us", url: "https://www.local-physio.co.uk/about" },
                            { label: "PT Software", url: "https://www.local-physio.co.uk/pt-software" },
                            { label: "Login", url: "https://www.local-physio.co.uk/login" },
                            { label: "Sign Up", url: "https://signup.local-physio.co.uk/" }
                        ].map(({ label, url }) => (
                            <Typography
                                key={label}
                                variant="gothamRegular"
                                component="a"
                                className={styles.footerLink}
                                href={url}
                                rel="noopener noreferrer"
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                    cursor: "pointer",
                                    display: "block",
                                }}
                            >
                                {label}
                            </Typography>
                        ))}

                    </Grid>
                    <Grid size={{ xs: 6, md: 2.5 }}>
                        <Typography variant="gothamMedium" className={styles.footerHeading}>
                            GET IN TOUCH
                        </Typography>
                        {[
                            { label: "Contact Us", url: "https://www.local-physio.co.uk/contact-us/" },
                            { label: "Membership Terms", url: "https://www.local-physio.co.uk/membership-terms/" },
                        ].map(({ label, url }) => (
                            <Typography
                                key={label}
                                variant="gothamRegular"
                                component="a"
                                className={styles.footerLink}
                                href={url}
                                rel="noopener noreferrer"
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                    cursor: "pointer",
                                    display: "block",
                                }}
                            >
                                {label}
                            </Typography>
                        ))}
                        <Typography variant="gothamMedium" className={`${styles.footerHeading} margin-top-10`}>
                            LEGAL
                        </Typography>
                        {[
                            { label: "Terms and Conditions", url: "https://www.local-physio.co.uk/website-terms-and-conditions/" },
                            { label: "Privacy Policy", url: "https://www.local-physio.co.uk/membership-terms/" },
                        ].map(({ label, url }) => (
                            <Typography
                                key={label}
                                variant="gothamRegular"
                                component="a"
                                className={styles.footerLink}
                                href={url}
                                rel="noopener noreferrer"
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                    cursor: "pointer",
                                    display: "block",
                                }}
                            >
                                {label}
                            </Typography>
                        ))}
                    </Grid>
                </Grid>
            </div>
            <div className={styles.footerBottom}>
                <Typography variant="body2" className={styles.footerBottomText}>
                    © Copyright 2024 Local Physio - All Rights Reserved
                </Typography>
            </div>
        </footer>
    );
};

export default Footer;
