import React, { useState } from "react";
import {
  TextField,
  RadioGroup,
  Radio,
  Checkbox,
  Select,
  FormControl,
  Box,
  Chip,
  Typography,
  MenuItem,
  InputAdornment,
  Link,
  Autocomplete
} from "@mui/material";
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import styles from "./formField.module.css";
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller } from 'react-hook-form';
import dayjs from "dayjs";

const FormField = ({ field, register, errors, onLinkButtonClick, selectedValues, setSelectedValues, control }) => {
  const [selectedRadioValue, setSelectedRadioValue] = useState("");
  const [selectedNumberValue, setSelectedNumberValue] = useState(null);
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [areaOfSpecialityArray, setAreaOfSpecialityArray] = useState([]);
  const [removedValues, setRemovedValues] = useState({});

  const handleChange = (event) => {
    setSelectedRadioValue(event.target.value);
  };

  const handleRemove = (category, value, event) => {
    event.stopPropagation();

    setSelectedValues((prev) => ({
      ...prev,
      [category]: prev[category]?.filter((v) => v !== value) || [],
    }));

    setRemovedValues((prev) => ({
      ...prev,
      [category]: [...(prev[category] || []), value],
    }));
  };

  const handleReAdd = (category, value) => {
    setSelectedValues((prev) => ({
      ...prev,
      [category]: [...(prev[category] || []), value],
    }));
    setRemovedValues((prev) => ({
      ...prev,
      [category]: prev[category]?.filter((v) => v !== value) || [],
    }));
  };


  const handleNumberInputChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.match(/^\d*\.?\d{0,2}$/) ? inputValue : selectedNumberValue;
    setSelectedNumberValue(formattedValue);
  };

  const setAdditionlServices = (key, options) => {
    setSelectedSpecialties((prevSelected) => {
      const isSelected = prevSelected.includes(key);
      const updatedSelected = isSelected
        ? prevSelected.filter((item) => item !== key)
        : [...prevSelected, key];
      setSelectedValues((prevValues) => {
        const updatedValues = { ...prevValues };
        if (isSelected) {
          delete updatedValues[key];
        } else {
          updatedValues[key] = Array.from(new Set([...(updatedValues[key] || []), ...options]));
        }
        return updatedValues;
      });
      setAreaOfSpecialityArray(updatedSelected);
      return updatedSelected;
    });
  };

  const renderInput = () => {
    switch (field.type) {
      case "text":
        return (
          <Box className={styles.inputWrapper} >
            <TextField
              label={field.placeholder}
              required={field.required}
              style={{ width: field?.width ?? "100%" }}
              sx={{ mb: 2 }}
              {...register(field.id)}
              error={!!errors[field.id]}
              helperText={errors[field.id]?.message}
            />
          </Box>
        );
      case "radio":
        return (
          <>
            <Box className={styles.inputWrapper} style={{ width: field?.width || "100%" }}>
              <RadioGroup row value={selectedRadioValue} onChange={handleChange} className={styles.radioGroup}>
                {field.options.map((option, index) => (
                  <Box key={index} className={styles.radioOptionBox}>
                    <Typography className={styles.radioOptionLabel}>{option}</Typography>
                    <Radio
                      className={styles.radioButton}
                      value={option}
                      required={field.required}
                      {...register(field.id)}
                      error={!!errors[field.id]}
                      helperText={errors[field.id]?.message}
                    />
                  </Box>
                ))}
              </RadioGroup>
            </Box>
            {errors[field.id] && <Typography variant="caption" color="error">{errors[field.id]?.message}</Typography>}
          </>
        );
      case "checkbox":
        return (
          <Box className={styles.checkboxContainer} style={{ flexDirection: !field.width ? "column" : "" }}>
            {field.id === 'areaOfSpeciality' && areaOfSpecialityArray.length === 0 && (
              <Typography variant="caption" color="error">
                At least one speciality is required
              </Typography>
            )}
            {field.options && Object.entries(field.options).map(([key, value], index) => (
              <>
                <Box
                  key={index}
                  style={{ width: field.width ?? "100%" }}
                  className={`${styles.checkboxItem} ${index === Object.keys(field.options).length - 1 ? styles.checkboxItemLast : ""}`}
                >
                  <Box className={styles.checkboxItemHeading}>
                    <span className={styles.checkboxLabel}>{key}</span>
                    <Checkbox
                      className={styles.checkbox}
                      checked={selectedSpecialties.includes(key)}
                      {...register(field.id)}
                      onChange={() => field.id !== 'areaOfSpeciality' ? onLinkButtonClick({ key, value }) : setAdditionlServices(key, value.options)}
                    />
                  </Box>
                  {selectedSpecialties?.includes(key) && value.options?.length > 0 && (
                    <Box className={styles.multiSelectContainer}>
                      <Box>
                        <Typography variant="subtitle1" color="textSecondary" className="margin-top-20">
                          {value.introText}
                        </Typography>

                      </Box>
                      <FormControl fullWidth>
                        <Select
                          multiple
                          value={selectedValues[key] || []}
                          error={!!errors[field.id]}
                          helperText={errors[field.id]?.message}
                          onChange={(event) => {
                            const value = event.target.value;
                            setSelectedValues((prev) => ({
                              ...prev,
                              [key]: value,
                            }));
                          }}
                          sx={{
                            height: '100% !important',
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none !important'
                            },
                            '& .MuiSelect-icon': {
                              display: 'none !important' // Hide the dropdown arrow
                            }
                          }}
                          renderValue={(selected) => {
                            return (
                              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip
                                    key={value}
                                    label={value}
                                    onDelete={(event) => handleRemove(key, value, event)}
                                    className={styles.chip}
                                    onMouseDown={(event) => event.stopPropagation()}
                                    classes={{
                                      deleteIcon: styles.deleteIcon
                                    }}
                                  />
                                ))}
                                {(removedValues[key] || []).map((value) => ( /* Prevents undefined errors */
                                  <Chip
                                    key={value}
                                    label={value}
                                    className={styles.removedChip} // Apply greyed-out style
                                    onMouseDown={(event) => {
                                      if (event) event.stopPropagation(); // Prevent dropdown from opening
                                    }}
                                    onClick={(event) => {
                                      if (event) event.stopPropagation(); // Prevent dropdown from opening
                                      handleReAdd(key, value);
                                    }}
                                    icon={<AddCircleIcon className={styles.addIcon} />}
                                  />
                                ))}
                              </Box>
                            );
                          }}
                        >

                          {value.options.map((option) => (
                            <MenuItem key={option} value={option}>
                              <Checkbox
                                checked={selectedValues[key]?.includes(option) || false}
                                className={styles.checkbox}
                              />
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                </Box>
              </>
            ))}
          </Box>
        );
      case "small-checkbox":
        return (
          <>
            <Box className={styles.checkboxContainer} style={{ flexDirection: !field.width ? "column" : "" }}>
              {field.options.map((option, index) => (
                <Box
                  key={index}
                  style={{ width: field.width ?? "100%" }}
                  className={`${styles.smallCheckboxItem} ${index === field.options.length - 1 ? styles.checkboxItemLast : ""
                    }`}
                >
                  <span className={styles.checkboxLabel}>{option}</span>
                  <Checkbox className={styles.checkbox} value={option}
                    {...register(field.id)}
                    error={!!errors[field.id]}
                    helperText={errors[field.id]?.message}
                  />
                </Box>
              ))}

            </Box>
            {errors[field.id] && <Typography variant="caption" color="error">At least one checkbox must be selected</Typography>}
          </>
        );
      case "no-border-checkbox":
        return (
          <>
            <Box className={styles.noBorderCheckboxContainer} style={{ flexDirection: !field.width ? "column" : "" }}>
              {field.options.map((option, index) => (
                <Box className="flex" key={index}>
                  <Checkbox className={styles.checkbox} value={option.value}
                    {...register(field.id)}
                    error={!!errors[field.id]}
                    helperText={errors[field.id]?.message}
                  />
                  <Typography variant="gothamRegular" className={styles.noBorderCheckboxLabel}>{option.label}</Typography>
                </Box>
              ))}
            </Box>
            {errors[field.id] && <Typography variant="caption" color="error"> At least one checkbox must be selected</Typography>}
          </>
        );
      case "checkbox-image":
        return (
          <>
            <Box className={styles.checkboxImageContainer}>
              {field.options.map((option, index) => {
                const [key, value] = Object.entries(option)[0];
                const image = require(`../../../app/assets/${value}`);
                return (
                  <Box key={index} className={styles.checkboxImageItem}>
                    <Box className={styles.checkboxImageBox}>
                      <Checkbox className={styles.checkbox} value={key}
                        {...register(field.id)}
                        error={!!errors[field.id]}
                        helperText={errors[field.id]?.message}
                      />
                    </Box>
                    {image && (
                      <img
                        src={image}
                        alt={key}
                        className={styles.checkboxImage}
                      />
                    )}
                    <span className={styles.checkboxImageLabel}>{key}</span>
                  </Box>
                );
              })}

            </Box>
            {errors[field.id] && <Typography variant="caption" color="error">At least one checkbox must be selected</Typography>}
          </>
        );
      case "dropdown":
        return (
          <Box className={styles.inputWrapper}>
            <Controller
              name={field.id}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  value={value || null}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  options={field.options || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => onLinkButtonClick(e.target.value)}
                      placeholder={field.placeholder || "Select an option"}
                      required={field.required}
                      error={!!errors[field.id]}
                      helperText={errors[field.id]?.message}
                    />
                  )}
                  className={styles.dropdown}
                  noOptionsText="No options available"
                />
              )}
            />
          </Box>
        );
      case "time":
        return (
          <>
            <Box className={styles.inputWrapper}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TimePicker
                      label={field.label}
                      value={value ? dayjs(value, "HH:mm") : null}
                      onChange={(newValue) => {
                        const formattedTime = newValue ? newValue.format("HH:mm") : "";
                        onChange(formattedTime);
                      }}
                      required={field.required}
                      className={styles.timePicker}
                      error={!!errors[field.id]}
                      helperText={errors[field.id]?.message}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select a time" variant="outlined" />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            {errors[field.id] && <Typography variant="caption" color="error">{errors[field.id]?.message}</Typography>}
          </>
        );
      case "link-button":
        return (
          <Box className={styles.inputWrapper}>
            <Link
              component="button"
              className={styles.linkButton}
              onClick={() => onLinkButtonClick && onLinkButtonClick(field)}
            >
              {field.linkTitle}
            </Link>
          </Box>
        );
      case "number-unit":
        return (
          <>
            <Box className={styles.inputWrapper} style={{ width: field?.width || "100%" }}>
              <TextField
                type="text"
                inputMode="decimal"
                placeholder={field.placeholder}
                required={field.required}
                className={styles.numberUnitInput}
                value={selectedNumberValue}
                {...register(field.id)}
                sx={{
                  '& .MuiInputBase-root': {
                    borderRadius: '8px',
                    padding: '0px',
                  },
                  '& .MuiInputBase-input': {
                    height: '48px',
                  },
                  '& .MuiInputAdornment-root': {
                    padding: '10px',
                    height: '90%',
                    marginTop: '4px !important',
                    fontWeight: '500 !important',
                    borderBottomLeftRadius: '8px !important',
                    borderTopLeftRadius: '8px !important',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className={styles.unit}>
                      {field.unit}
                    </InputAdornment>
                  ),
                }}
                fullWidth
                onChange={handleNumberInputChange}
              />
            </Box>
            {errors[field.id] && (
              <Typography variant="caption" color="error">
                Input must be a positive number
              </Typography>
            )}
          </>
        );
      case "textarea":
        return (
          <Box className={styles.inputWrapper} style={{ width: field?.width || "100%" }}>
            <TextField
              label={field.label}
              multiline
              rows={field.rows}
              {...register(field.id)}
              error={!!errors[field.id]}
              helperText={errors[field.id]?.message}
              fullWidth
              required={field.required}
              placeholder={field.placeholder || "Enter your text here"}
              sx={{
                '& .MuiInputBase-root': {
                  height: '100% !important',
                }
              }}
            />
          </Box>
        );
      case "file":
        return (
          <Box className={styles.inputWrapper}>
            {field.src ? (
              <Box className={styles.imagePreview} onClick={() => onLinkButtonClick(field)}>
                <img
                  src={field.src}
                  alt="Uploaded"
                  style={{ width: "263px", height: "263px", objectFit: "cover", borderRadius: "8px" }}
                />
              </Box>
            ) : (
              <Box className={styles.imageContainer} onClick={() => onLinkButtonClick(field)}>
                <InsertPhotoOutlinedIcon sx={{ fontSize: 50, color: "#686868" }} />
                <Typography variant="gothamRegular" className="margin-top-15 f-500">
                  Click to upload clinic's logo
                </Typography>
              </Box>
            )}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {renderInput()}
    </div>
  );
};

export default FormField;
