const paletteColors = {
    primaryColor: {
        main: '#00AA9C',
    },
    backgroundColor: {
        main: '#FFFF'
    },
    textColor: {
        main: '#000000'
    },
    footerColor: {
        main: '#333333'
    },
    headerColor: {
        main: '#f6f6f6'
    }
};

export default paletteColors;