import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import Header from "./components/Layout/header";
import Footer from "./components/Layout/footer";
import "./styles/fonts.css";
import "./global.css";
import { useNavigate } from 'react-router-dom';

const RootLayout = () => {
  const location = useLocation();
  const shouldShowHeaderFooter = !location.pathname.includes("/onboard");
  const navigate = useNavigate();

  useEffect(() => {
    const currentUserId = localStorage.getItem("LPCurrentUserId");
    const currentUserStep = localStorage.getItem("currentCompleted");
    if (currentUserId) {
      if (!location.pathname.includes("/payment-success") && currentUserStep === 3) {
        localStorage.removeItem("currentCompleted");
        localStorage.removeItem("LPCurrentUserId");
        localStorage.removeItem("LPCurrentUserEmail");
      } else if (!location.pathname.includes("/payment-success") && currentUserStep !== 3) {
        navigate('/onboard');
      }
    } else {
      navigate('/signup');;
    }
  }, [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box component="main" className="main-grid-container">
        {shouldShowHeaderFooter && <Header />}
        <Outlet />
        {shouldShowHeaderFooter && <Footer />}
      </Box>
    </ThemeProvider>
  );
};

export default RootLayout;
