'use client';
import paletteColors from './palette';
import { createTheme } from '@mui/material/styles';
import MuiTypography from './components/MuiTypography';

const theme = createTheme({
    palette: {
        ...paletteColors,
    },
    typography: {
        fontFamily: 'Gotham, sans-serif',
        gothamXS: {
            fontFamily: 'Gotham, sans-serif',
            fontSize: '10px',
            fontWeight: 400,
            fontStyle: 'normal',
        },
        gothamSmall: {
            fontFamily: 'Gotham, sans-serif',
            fontSize: '12px',
            fontWeight: 400,
            fontStyle: 'normal',
        },
        gothamRegular: {
            fontFamily: 'Gotham, sans-serif',
            fontSize: '14px',
            fontWeight: 400,
            fontStyle: 'normal',
        },
        gothamMedium: {
            fontFamily: 'Gotham, sans-serif',
            fontSize: '16px',
            fontWeight: 400,
            fontStyle: 'normal',
        },
        gothamMediumLarge: {
            fontFamily: 'Gotham, sans-serif',
            fontSize: '18px',
            fontWeight: 400,
            fontStyle: 'normal',
        },
        gothamLarge: {
            fontFamily: 'Gotham, sans-serif',
            fontSize: '20px',
            fontWeight: 400,
            fontStyle: 'normal',
        },
        gothamXL: {
            fontFamily: 'Gotham, sans-serif',
            fontSize: '24px',
            fontWeight: 700,
            fontStyle: 'normal',
        },
        gotham2XL: {
            fontFamily: 'Gotham, sans-serif',
            fontSize: '30px',
            fontWeight: 700,
            fontStyle: 'normal',
        },
        gotham3XL: {
            fontFamily: 'Gotham, sans-serif',
            fontSize: '35px',
            fontWeight: 700,
            fontStyle: 'normal',
        },
        gotham4XL: {
            fontFamily: 'Gotham, sans-serif',
            fontSize: '40px',
            fontWeight: 700,
            fontStyle: 'normal',
        },
        gotham5XL: {
            fontFamily: 'Gotham, sans-serif',
            fontSize: '46px',
            fontWeight: 700,
            fontStyle: 'normal',
        }
    },
    components: {
        MuiTypography,
        MuiTab: {
            styleOverrides: {
                root: {
                    padding: '14px 18px',
                    fontFamily: 'Gotham, sans-serif',
                },
            },
        },
    }
});

export default theme;
