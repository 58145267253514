import React from 'react';
import { Box, Typography, Card, CardContent, Button, Grid, Divider } from '@mui/material';
import styles from './styles.module.css';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

const SignupInfoSection = () => {
    const systemImg = require(`../../app/assets/Image.png`);
    return (
        <Box className={styles.physioSection}>
            <Typography variant="gotham2XL" className={styles.sectionTitle}>
                How Local Physio Works
            </Typography>
            <Typography variant="body1" className={styles.sectionSubtitle}>
                We connect you with patients actively seeking physiotherapy services in your area, helping you fill your schedule with qualified bookings.
            </Typography>
            <Box className={styles.infoCards}>
                <Card className={styles.infoCard}>
                    <CardContent>
                        <Box className={styles.iconContainer}>
                            <AssignmentTurnedInOutlinedIcon />
                        </Box>
                        <Typography variant="h6">Get Listed & Be Found</Typography>
                        <Typography variant="body2">
                            Join Local-Physio in Minutes! Simply provide your clinic details, and our team will review and approve your listing.
                            We'll reach out if we need more info. Otherwise, you're all set to start attracting new patients.
                        </Typography>
                    </CardContent>
                </Card>

                <Card className={styles.infoCard}>
                    <CardContent>
                        <Box className={styles.iconContainer}>
                            <CampaignOutlinedIcon />
                        </Box>
                        <Typography variant="h6">More Visibility, More Patients</Typography>
                        <Typography variant="body2">
                            Let Patients Find You First! We use proven SEO strategies to ensure your clinic ranks higher in local searches,
                            helping you stand out and get noticed by the right patients.
                        </Typography>
                    </CardContent>
                </Card>

                <Card className={styles.infoCard}>
                    <CardContent>
                        <Box className={styles.iconContainer}>
                            <TrendingUpOutlinedIcon />
                        </Box>
                        <Typography variant="h6">Watch Your Patient List Expand</Typography>
                        <Typography variant="body2">
                            Get More Enquiries & Bookings, Hassle-Free! Once your listing is live, patients searching for physiotherapy can easily
                            find and contact you—helping you grow your practice effortlessly.
                        </Typography>
                    </CardContent>
                </Card>
            </Box>

            <Box className={styles.pricingSection}>
                <Typography variant="gotham2XL" className={styles.pricingTitle}>
                    Cost-Effective Plans with No Surprises
                </Typography>
                <Typography variant="body1" className={styles.pricingSubtitle}>
                    Join for less than the cost of one appointment.
                </Typography>

                <Box className={styles.pricingCards}>
                    <Box className={styles.pricingCardContainer}>
                        <Box className={styles.recommendedTag}>RECOMMENDED</Box>
                        <Card className={`${styles.pricingCard} ${styles.anualPricingCard}`}>
                            <Typography className={styles.duration} variant="body2">Annually</Typography>
                            <CardContent className={styles.pricingCardContent}>
                                <Box>
                                    <Typography className={styles.priceTag} variant="h4">£14<Typography variant='gothamSmall'>/month</Typography></Typography>
                                    <Typography variant="gothamXS">£168 billed annually</Typography>
                                </Box>
                                <Box className={styles.discountContainer}>
                                    <Typography variant='gothamSmall' className={styles.discountTag}>-30% off</Typography>
                                    <Typography variant="gothamXS" className={styles.discountTag}>(Limited time offer)</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                    <Card className={styles.pricingCard}>
                        <Typography className={styles.duration} variant="body2">Monthly</Typography>
                        <CardContent className={styles.pricingCardContent}>
                            <Box>
                                <Box className={styles.priceTag}><Typography variant='h4'>£20</Typography><Typography variant='gothamSmall'>/month</Typography></Box>
                                <Typography variant="gothamXS">£20 billed Monthly</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>

                <Button variant="contained" className={styles.listClinicButton}>List your clinic</Button>
            </Box>
            <Box className={styles.sectionContainer}>
                <Typography variant="gotham2XL" className={styles.sectionTitle}>
                    Reach 100s of New Patients
                </Typography>
                <Typography variant="body1" className={styles.sectionSubtitle}>
                    Patients trust clinics they can find easily online. A strong listing gives your practice the credibility and exposure needed to grow.
                </Typography>
                <Grid container spacing={3} className={styles.patientsContainer}>
                    <Grid item xs={12} md={6} className={styles.patientsSection}>
                        <CheckCircleOutlinedIcon className={styles.checkIcon} />
                        <Typography variant="h6" className={styles.bulletPoint}>Get Found by Patients Actively Looking for Treatment</Typography>
                        <Typography variant="body2">
                            Every day, patients search online for physiotherapy services. A strong online presence ensures they discover your clinic and choose you over competitors.
                        </Typography>
                        <Divider className={styles.patientDivider} />
                        <CheckCircleOutlinedIcon className={styles.checkIcon} />
                        <Typography variant="h6" className={styles.bulletPoint}>Convert Online Searches into Booked Appointments</Typography>
                        <Typography variant="body2">
                            When patients search for physiotherapy, they’re ready to book. A strong listing makes it easy for them to find your clinic, get in touch, and schedule an appointment.
                        </Typography>
                        <Divider className={styles.patientDivider} />
                        <CheckCircleOutlinedIcon className={styles.checkIcon} />
                        <Typography variant="h6" className={styles.bulletPoint}>A Cost-Effective Way to Grow Your Practice</Typography>
                        <Typography variant="body2">
                            For less than the price of a single appointment, your clinic gains continuous online exposure, helping you attract more patients and grow with ease.
                        </Typography>
                        <Divider className={styles.patientDivider} />
                    </Grid>
                    <Grid item xs={12} md={6} className='margin-top-30'>
                        <img src={systemImg} alt="Local Physio Listing" className={styles.listingImage} />
                    </Grid>
                </Grid>
            </Box>
            <Box className={styles.pricingSection}>
                <Typography variant="gotham2XL" className={styles.sectionTitle}>
                    Key Benefits of Local Physio
                </Typography>
                <Grid container spacing={3} className={styles.cardContainer}>
                    <Grid item xs={12} sm={6} md={2.5}>
                        <Card className={styles.benefitCard}>
                            <CardContent>
                                <Box className={styles.iconContainer}>
                                    <SearchOutlinedIcon />
                                </Box>
                                <Typography variant="h6" className={styles.cardTitle}>Build Trust & Credibility</Typography>
                                <Typography variant="body2">
                                    A professional listing on a recognised platform reassures patients that your clinic is a trusted, reputable choice for physiotherapy care.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.5}>
                        <Card className={styles.benefitCard}>
                            <CardContent>
                                <Box className={styles.iconContainer}>
                                    <GroupsOutlinedIcon />
                                </Box>
                                <Typography variant="h6" className={styles.cardTitle}>Attract the Right Patients</Typography>
                                <Typography variant="body2">
                                    We target specific keywords and demographics so your clinic reaches the right people—those who need your expertise and are ready to book.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.5}>
                        <Card className={styles.benefitCard}>
                            <CardContent>
                                <Box className={styles.iconContainer}>
                                    <CalendarTodayOutlinedIcon />
                                </Box>
                                <Typography variant="h6" className={styles.cardTitle}>Seamless Patient Connection</Typography>
                                <Typography variant="body2">
                                    Make it easy for patients to find, contact, and book with your clinic directly—without unnecessary friction.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.5}>
                        <Card className={styles.benefitCard}>
                            <CardContent>
                                <Box className={styles.iconContainer}>
                                    <AccessTimeOutlinedIcon />
                                </Box>
                                <Typography variant="h6" className={styles.cardTitle}>Grow Your Practice with Minimal Effort</Typography>
                                <Typography variant="body2">
                                    With automated visibility strategies and an optimised profile, your clinic continuously attracts new patients while you focus on care.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Box className={styles.bottomSection}>
                <Typography variant="gotham2XL" className={styles.sectionTitle}>
                    More Visibility. More Patients. More Bookings.
                </Typography>
                <Typography variant="body1" className={styles.sectionSubtitle}>
                    Being visible online means more than just having a website. Local Physio helps you rank higher, attract the right patients, and turn searches into appointments. It’s the easiest way to grow your practice without extra work.
                </Typography>
                <Button variant="contained" className={styles.listClinicButton}>List your clinic</Button>
            </Box>
        </Box>

    );
};

export default SignupInfoSection;
