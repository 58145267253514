import React, { useState, useCallback, useMemo } from "react";
import styles from "./header.module.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import { HEADER_TABS } from "../../config/constants";
import Logo from "../../app/assets/logo.png"

const drawerWidth = 240;

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const Header = () => {
  const [currentTabValue, setCurrentTabValue] = useState("");
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleScroll = (tab) => {
    let url = "";
    switch (tab) {
      case "Home":
        url = "https://www.local-physio.co.uk/";
        break;
      case "Health A-Z":
        url = "https://www.local-physio.co.uk/articles/";
        break;
      case "Log in":
        url = "https://www.local-physio.co.uk/login/";
        break;
      case "Practice":
        url = "https://signup.local-physio.co.uk/"
        break;
      default:
        return;
    }
    window.location.href = url;
  };

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prevState) => !prevState);
  }, []);

  const drawer = useMemo(
    () => (
      <Box onClick={handleDrawerToggle} className="text-center">
        <List>
          {HEADER_TABS.map((item) => (
            <ListItem key={item} disablePadding>
              <ListItemButton
                className={`text-left`}
                onClick={() => {
                  setCurrentTabValue(item);
                  handleScroll(item);
                }}
              >
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem disablePadding>
            <ListItemButton
              className="text-left"
              onClick={() => handleScroll("Practice")}
            >
              <ListItemText primary="List Your Practice" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    ),
    [handleDrawerToggle]
  );

  const container = typeof window !== "undefined" ? () => window.document.body : undefined;

  return (
    <>
      <AppBar component="nav" color="default" className={styles.navBar}>
        <Toolbar className={styles.toolbar}>
          <Box component="div" className={styles.logo}>
            <img src={Logo} height={22} width={180} alt="header-logo" />
          </Box>
          <Box className="flex align-center">
            <Tabs value={currentTabValue} aria-label="navigation tabs" className="align-center" sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}>
              {HEADER_TABS.map((tab, tabIndex) => (
                <Tab
                  className={styles.tabFontSize}
                  key={`tab-${tab}-${tabIndex}`}
                  {...a11yProps(tabIndex)}
                  label={
                    <Typography
                      variant="gothamRegular"
                      style={{
                        fontWeight: "bold",
                        color: "#333333",
                      }}
                    >
                      {tab}
                    </Typography>
                  }
                  value={tab}
                  onClick={() => {
                    setCurrentTabValue(tab);
                    handleScroll(tab);
                  }}
                  sx={{
                    "&.Mui-selected": {
                      borderBottom: "3px solid #00AA9C",
                    },
                  }}
                />
              ))}

              <Typography
                variant="gothamRegular"
                className={styles.listButton}
                onClick={() => handleScroll('Practice')}
              >
                List Your Practice
              </Typography>

            </Tabs>
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={styles.menuIcon}
            sx={{ mr: 2, padding: 0, display: { sm: "none" } }}
          >
            <MenuIcon className={styles.iconMenu} height={40} width={40} />
          </IconButton>
        </Toolbar>
      </AppBar >
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
};

export default Header;
